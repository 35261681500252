<template>
    <div class="body">

        <el-card >

            <el-table
                :data="tableData"
                style="width: 100%"
                border>

                <el-table-column
                    prop="name"
                    label="真实姓名"
                    min-width="100">
                </el-table-column>

                <el-table-column
                    prop="amount"
                    label="提现金额(rmb)"
                    min-width="140">
                </el-table-column>

                <el-table-column
                    prop="coin"
                    label="工钱"
                    min-width="80">
                </el-table-column>

                <el-table-column
                    prop="username"
                    label="用户名"
                    min-width="110">
                </el-table-column>

                <el-table-column
                    prop="state"
                    label="状态"
                    min-width="110">
                    <template slot-scope="scope">
                        <span v-if="scope.row.state == 1">未处理</span>
                        <span v-if="scope.row.state == 2">暂缓处理</span>
                        <span v-if="scope.row.state == 3">冻结提现</span>
                        <span v-if="scope.row.state == 4">退回提现</span>
                        <span v-if="scope.row.state == 5">已完成</span>

                    </template>
                </el-table-column>

                <el-table-column
                    prop="account"
                    label="支付宝账号"
                    min-width="150">
                </el-table-column>
                
                <el-table-column
                    prop="createTime"
                    label="创建时间"
                    min-width="150">
                </el-table-column>

                <el-table-column
                    fixed="right"
                    label="操作"
                    min-width="120">
                    <template slot-scope="scope">
                        <el-button
                        @click.native.prevent="changeRow(scope.row)"
                        type="text"
                        size="small">
                        修改状态
                        </el-button>
                    </template>
                </el-table-column>
            
            </el-table>

            <el-pagination
                :page-size="pageSize"
                :current-page="pageNumber"
                layout="prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :total="total">
            </el-pagination>

        </el-card>

        <el-dialog
            title="修改状态"
            :visible.sync="dialogVisible"
            width="60%"
            :before-close="handleClose">
                
                <div class="dialogWrap">
                    <!-- 1 未处理，2 暂缓处理，3 冻结提现，4 退回提现，5 已完成 -->

                    <div v-for="item of radioList" :key="item.text" class="radioWrap">
                        <el-radio v-model="curEditStatus" :disabled="item.disabled" :label="item.label">{{ item.text }}</el-radio>
                    </div>

                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">取 消</el-button>
                    <el-button type="primary" @click="sureChangeClick()">确 定</el-button>
                </span>
        </el-dialog>



    </div>
</template>
  
  
<script>
  // 引入组件及方法
  
  export default {
    components: {  },
    data() {
      return {
        tableData:[],
        total:0,
        pageNumber:0,
        pageSize:10,
        dialogVisible:false,
        curEditStatus:1,
        curEditId:0,
        radioList:[
            {
                text:"未处理",
                label:1,
                disabled:false
            },{
                text:"暂缓处理",
                label:2,
                disabled:false
            },{
                text:"冻结提现",
                label:3,
                disabled:false
            },{
                text:"退回提现",
                label:4,
                disabled:false
            },{
                text:"已完成",
                label:5,
                disabled:false
            }
        ]
      };
    },
    mounted(){

      this.getData()



    },
    methods: {
        // 获取数据
        getData() {
            
            this.$https('GET_ORDER_WITHDRAWPAGE', {
                pageNumber: this.pageNumber,
                pageSize: this.pageSize,
                payType: 1
            }).then(res => {
                res.data.records.forEach((val)=>{
                    val.amount = val.amount/100
                })
                this.tableData = res.data.records
                this.total = parseInt(res.data.total)
            })
        },
        changeRow(item){
            this.curEditStatus = item.state
            this.curEditId = item.id
            this.setRadioList()

            this.dialogVisible = true
        },
        handleClose(){
            this.dialogVisible = false

        },
        setRadioList(){

            if(this.curEditStatus == 1){
                this.radioList.forEach((val)=>{
                    val.disabled = false
                })
            }else if(this.curEditStatus == 2){
                this.radioList.forEach((val)=>{
                    if(val.label == 3 || val.label == 4 || val.label == 5){
                        val.disabled = false
                    }else if(val.label == 2){
                        val.disabled = false
                    }else{
                        val.disabled = true
                    }
                })
            }else if(this.curEditStatus == 3 || this.curEditStatus == 4 || this.curEditStatus == 5){
                this.radioList.forEach((val)=>{
                    val.disabled = true
                })
            }

        },
        async sureChangeClick(){

            var res = await this.$https('UPDATE_ORDER_WITHDRAW_STATUS', {
                state: this.curEditStatus,
                id: this.curEditId
            })
            console.log("更新status", res)
            
            //  Submitted: 提交未处理
            //  Paused: 暂缓
            //  Frozen: 冻结
            //  Completed: 已完成
            //  Refused: 退回



            this.dialogVisible = false
            this.getData()

        },
        handleSizeChange(size){
            this.pageSize = size
            this.getData()
        },
        handleCurrentChange(num){
            this.pageNumber = num
            this.getData()
        }
    },
  };
</script>
  
<style scoped >
.body {
    width: 100%;
    height: 100%;
}
.dialogWrap{
    padding: 20px 0 20px 80px;
    box-sizing: border-box;
    
}
.radioWrap{
    padding: 10px 0;
}
</style>
  